<template>
  <div class="hae-first-result">
    <img
      alt="title logo"
      src="../../assets/hae/result1.svg"
      class="topset-logo"
    />
    <ResultBox v-for="q in questions" :question="q" :key="'screening' + q.id" />
    <br />
    <div class="title-box">
      <p class="heading-text white-text">HAEセルフチェック結果</p>
    </div>
    <div class="white-box">
      <p class="text-block">
        3つとも「いいえ」にあてはまる方で、むくみだけでなく、呼吸が苦しい、息切れ、動悸、胸痛、尿の出が悪い、疲れやすいといった症状があるときは、心臓、肝臓や腎臓などの病気によるむくみの可能性があります。
      </p>
      <a
        :href="fetchTopicAddFormUrl"
        class="lineless"
        @click="sendGa('click', 'hae', 'add-topics-from-first-result')"
      >
        <CommonButton
          text="AskDoctorsで今すぐ医師に相談"
          styleType="active"
        />
      </a>
    </div>

    <QuoteOrigin />
  </div>
</template>

<script>
import rootStore from "@/store";
import { questions } from "../../data/hae";
import ResultBox from "@/components/hae/ResultBox.vue";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
import CommonButton from "@/components/hae/CommonButton.vue";
const state = rootStore.state;
export default {
  name: "HaeFirstResult",
  components: { ResultBox, QuoteOrigin, CommonButton },
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  beforeCreate: function() {
    Promise.all([
      this.axios.post(
        process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/hae_answers",
        {
          version_tag: "v01",
          answer1: JSON.stringify(state.hae.screeningAnswers[0]) || "null",
          answer2: JSON.stringify(state.hae.screeningAnswers[1]) || "null",
          answer3: JSON.stringify(state.hae.screeningAnswers[2]) || "null",
          answer4: JSON.stringify(state.hae.screeningAnswers[3]) || "null",
          answer5: JSON.stringify(state.hae.screeningAnswers[4]) || "null",
          answer6: JSON.stringify(state.hae.screeningAnswers[5]) || "null",
          answer7: JSON.stringify(state.hae.screeningAnswers[6]) || "null",
          answer8: JSON.stringify(state.hae.screeningAnswers[7]) || "null",
          answer9: JSON.stringify(state.hae.screeningAnswers[8]) || "null",
          answer10: JSON.stringify(state.hae.screeningAnswers[9]) || "null",
          answer11: JSON.stringify(state.hae.screeningAnswers[10]) || "null",
          answer12: JSON.stringify(state.hae.screeningAnswers[11]) || "null",
          answer13: JSON.stringify(state.hae.screeningAnswers[12]) || "null",
          answer14: JSON.stringify(state.hae.screeningAnswers[13]) || "null",
          answer15: JSON.stringify(state.hae.screeningAnswers[14]) || "null",
          answer16: JSON.stringify(state.hae.screeningAnswers[15]) || "null",
          answer17: JSON.stringify(state.hae.screeningAnswers[16]) || "null",
          answer18: JSON.stringify(state.hae.screeningAnswers[17] || "null")
        },
        { withCredentials: true }
      )
    ]).catch(error => {
      console.error(error);
    });
  },
  data: function() {
    return {
      questions: questions().slice(0, 3)
    };
  },
  computed: {
    fetchTopicAddFormUrl() {
      return process.env.VUE_APP_API_BASE_URL + "topics/add-form";
    }
  }
};
</script>

<style scoped>
@import "../../assets/style/hae.css";
.headline {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  width: 192px;
  margin: 30px auto;
}
</style>
