













import Vue from "vue";
import rootStore from "@/store";
import { HaeQuestion } from "@/types/hae";
import { HaeState } from "@/types/hae";
const state = rootStore.state as { hae: HaeState };

export default Vue.extend({
  name: "ResultBox",
  props: { question: Object as Vue.PropType<HaeQuestion> },
  computed: {
    answerText(): string {
      const answerNum = state.hae.screeningAnswers[this.question.id - 1];
      if (typeof answerNum !== "number") {
        return "";
      }
      return this.question.choices[answerNum];
    }
  }
});
